import React from 'react';
import './styles/contact.scss';
import Layout from '../components/layout';

export default () => (
	<Layout>
		<div>
			<h1>contact</h1>
		</div>
	</Layout>
)
